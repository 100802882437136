import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class BMTestsRepository extends BaseRepository {
    baseUrl         = 'bituminous-mixtures/mixtures/bm-tests';
    originalBaseUrl = 'bituminous-mixtures/mixtures/bm-tests/';

    /**
     * Set type of BMTest
     * @param type
     */
    setType(type) {
        this.baseUrl = this.originalBaseUrl + type;
    }

    /**
     * Fetches the next (suggested) number
     *
     * @returns {*}
     */
    nextNumber() {
        return this.httpClient.get(`${this.baseUrl}/next-number`);
    }

    /**
     * Generates a zip with records' bulletin files
     *
     * @returns {*}
     */
    generateZip(criteria) {
        return this.httpClient.post(`${this.baseUrl}/generate-zip`, criteria);
    }

    /**
     * Returns reference tests samples
     *
     * @returns {*}
     */
    referenceSamples() {
        return this.httpClient.get(`${this.baseUrl}/reference-samples`);
    }

    /**
     * Validate a given record
     *
     * @param id
     * @return {*}
     */
    validate(id) {
        return this.httpClient.patch(`${this.baseUrl}/${id}/validate`);
    }

    /**
     * Invalidate a given record
     *
     * @param id
     * @return {*}
     */
    invalidate(id) {
        return this.httpClient.patch(`${this.baseUrl}/${id}/invalidate`);
    }

    /**
     * Verify a given record
     *
     * @param id
     * @return {*}
     */
    verify(id) {
        return this.httpClient.patch(`${this.baseUrl}/${id}/verify`);
    }

    /**
     * Validate selected records
     *
     * @param selectedRows
     *
     * @return {*}
     */
    validateSelected(selectedRows) {
        return this.httpClient.patch(`${this.baseUrl}/validate-selected`, { selectedRows });
    }

    /**
     * Invalidate selected records
     *
     * @param selectedRows
     *
     * @return {*}
     */
    invalidateSelected(selectedRows) {
        return this.httpClient.patch(`${this.baseUrl}/invalidate-selected`, { selectedRows });
    }

    /**
     * Verify selected records
     *
     * @param selectedRows
     *
     * @return {*}
     */
    verifySelected(selectedRows) {
        return this.httpClient.patch(`${this.baseUrl}/verify-selected`, { selectedRows });
    }

    /**
     * Performs a search given some criteria, returns datatable
     *
     * @param sectorId
     * @param criteria
     *
     * @returns {*}
     */
    search(sectorId, criteria) {
        return this.httpClient.post(`${this.baseUrl}/search`, { sector_id: sectorId, ...criteria });
    }

    /**
     * Creates a record
     *
     * @param sectorId
     * @param data
     *
     * @returns {*}
     */
    create(sectorId, data) {
        return this.httpClient.post(`${this.baseUrl}`, { sector_id: sectorId, ...data });
    }

    /**
     * Updates a record
     *
     * @param sectorId
     * @param id
     * @param data
     *
     * @returns {*}
     */
    update(sectorId, id, data) {
        return this.httpClient.put(`${this.baseUrl}/${id}`, { sector_id: sectorId, ...data });
    }

    /**
     * gets all lab tests for a given lot that are validated
     *
     * @param lotInterventionId
     * @return {*}
     */
    validatedLabByLotIntervention(lotInterventionId) {
        const criteria = lotInterventionId ? { lot_intervention_id: lotInterventionId } : {};
        return this.httpClient.post(`${this.baseUrl}/lab/validated-by-lot-intervention`, criteria);
    }
}
