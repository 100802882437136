import { inject }            from 'aurelia-framework';
import { PLATFORM }          from 'aurelia-pal';
import { BaseFormViewModel } from 'base-form-view-model';
import { FormSchema }        from 'modules/bituminous-mixtures/mixtures/bm-tests/form-schema';
import { BMTestsRepository } from 'modules/bituminous-mixtures/mixtures/bm-tests/services/repository';
import { AppContainer }      from 'resources/services/app-container';

@inject(AppContainer, BMTestsRepository, FormSchema)
export class ViewBMTest extends BaseFormViewModel {

    headerTitle = 'form.title.view-record';

    alert    = {};
    model    = {};
    tabs     = {};
    create   = false;
    readonly = true;

    constructor(appContainer, repository, formSchema) {
        super(appContainer);
        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/bm-tests/form.html');
    }

    /**
     * Handles canActivate event
     */
    async canActivate(params) {
        this.repository.setType(this.type);
        this.routePrefix      = `bituminous-mixtures.mixtures.${this.type}`;
        this.permissionPrefix = this.routePrefix;
        this.formId           = `${this.permissionPrefix}.view-form`;

        this.backRoute = `${this.routePrefix}.index`;

        if (typeof this.type === 'undefined' || typeof this.sectorId === 'undefined') {
            console.warn('BM Test type and sector are undefined');
            return false;
        }

        return this.redirectToSelectLotIntervention(`${this.routePrefix}.view`, params) && super.can([
            this.permissionPrefix + '.manage',
            this.permissionPrefix + '.view',
        ]);
    }

    /**
     * Handles activate event
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        await this.fetchData(params);

        if(this.model.is_validated) {
            this.alert = this.infoAlertMessage('form.message.viewing-validated-record');
        }

        // Get schema for each tab
        this.tabs.selectBulletins       = await this.formSchema.selectBulletinsSchema.schema(this, true);
        this.tabs.general               = this.formSchema.generalSchema.schema(this, true);
        this.tabs.general.lotInfoSchema = this.formSchema.lotInfoSchema(this, true);
        this.tabs.harvestData           = await this.formSchema.harvestDataSchema.schema(this, true);
        this.tabs.additionalInfo        = this.formSchema.additionalInfoSchema.schema(this, true);
        this.globalSchema               = this.formSchema.globalSchema(this, true);
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.update(this.sectorId, this.model.id, this.model)
            .then((response) => {
                this.handleResponse(response, `${this.routePrefix}.index`);
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchData(params) {
        const response = await this.repository.find(params.id);
        this.initialModel.assign(response);
        this.model.assign(response);
    }
}
