import { EventAggregator }             from 'aurelia-event-aggregator';
import { inject }                      from 'aurelia-framework';
import { I18N }                        from 'aurelia-i18n';
import { HighwaysRepository }          from 'modules/management/concessions/concessions-tree/highways/services/repository';
import { InterventionTypesRepository } from 'modules/management/concessions/concessions-tree/intervention-types/services/repository';
import { OEDatasRepository }           from 'modules/management/concessions/concessions-tree/lot-constructions/oe-data/services/repository';
import { LotInterventionsRepository }  from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { PkTypesRepository }           from 'modules/management/concessions/concessions-tree/pk-types/services/repository';
import { EntitiesRepository }          from 'modules/management/concessions/entities/services/repository';
import { TeamsRepository }             from 'modules/management/concessions/teams/services/repository';
import { FieldsRepository }            from 'modules/management/dynamic-fields/association-sectors/services/fields-repository';
import { LotConstructionType }         from 'modules/management/models/lot-construction-type';
import { ConcessionsRepository }       from 'modules/management/specifications/concessions/services/repository';
import { ListingRevisionsRepository }  from 'modules/management/specifications/listings/listing-revisions/services/repository';
import { ListingsRepository }          from 'modules/management/specifications/listings/services/repository';
import { BaseFilterFormSchema }        from 'resources/classes/base-filter-form-schema';
import { SessionStorage }              from 'resources/services/session-storage';

@inject(SessionStorage, EventAggregator, I18N, ConcessionsRepository, EntitiesRepository, TeamsRepository, HighwaysRepository, InterventionTypesRepository, FieldsRepository, ListingsRepository, ListingRevisionsRepository, LotInterventionsRepository, OEDatasRepository, PkTypesRepository)
export class ConstructionFilterFormSchema extends BaseFilterFormSchema {

    constructor(storage, eventAggregator, i18n, oeDatasRepository, pkTypesRepository) {
        super(storage, eventAggregator, i18n);

        this.oeDatasRepository = oeDatasRepository;
        this.pkTypesRepository = pkTypesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns
     */
    model(viewModel, selectedLotInformation) {
        this.modelDefaults = {
            ...this.modelDefaults,
            entity_name: selectedLotInformation.entity_name,
            entity_id:   selectedLotInformation.entity_id,
            team_name:   selectedLotInformation.team_name,
            team_id:     selectedLotInformation.team_id,
        };

        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    async schema(viewModel) {

        const branchLabel        = 'form.field.branch';
        const edgeLabel          = 'form.field.edge';
        const notApplicableLabel = 'form.text.not-applicable';

        const filterData = async (newValue) => {
            return await this.oeDatasRepository.filterData(newValue);
        }

        const filterEdgeBranchPK = async (newValue) => {

            let branch = this.branch_id;
            let edge   = this.edge_id;

            edge.instance.hide(false);
            branch.instance.hide(false);
            edge.instance.clear(!newValue);
            branch.instance.clear(!newValue);

            branch.instance.disable(!newValue);
            edge.instance.disable(!newValue);
            await updateOELotConstructionData();

            /* Get filtered data from backend */
            const { predominant, branch_options, edge_options } = await filterData(newValue);

            edge.label   = edgeLabel;
            branch.label = branchLabel;

            switch (predominant) {
                case 'branch_id':
                    edge.instance.hide();

                    branch.options = branch_options;

                    edge.label = notApplicableLabel;
                    break;

                case 'edge_id':
                    branch.instance.hide();

                    edge.options = edge_options;

                    branch.label = notApplicableLabel;
                    break;

                case 'pk_id':
                    edge.instance.hide();
                    branch.instance.hide();

                    branch.label = notApplicableLabel;
                    edge.label   = notApplicableLabel;
                    break;
            }

            branch.instance.disable(predominant !== 'branch_id')
            edge.instance.disable(predominant !== 'edge_id')
        }

        const updateOELotConstructionData = async (source = null) => {
            // Early bird gets the worm.
            if (!source) {
                viewModel.filterModel.o_e_lot_construction_data_id = null;
                viewModel.filterModel.number_of_tracks             = null;
                viewModel.filterModel.axis                         = null;
                return;
            }

            let selectedRecord = source.instance.selectedRecord();
            let oeDataId       = selectedRecord.o_e_lot_construction_data_id;

            let oeData = await this.oeDatasRepository.find(oeDataId);

            viewModel.filterModel.o_e_lot_construction_data_id = oeDataId;
            viewModel.filterModel.number_of_tracks             = oeData.number_of_lanes;
            viewModel.filterModel.axis                         = oeData.axis;
        }

        this.lot_construction_id = {
            type:         'select2',
            key:          'lot_construction_id',
            label:        'form.field.local',
            remoteSource: () => viewModel.lot_intervention_id
                ? this.lotInterventionsRepository.allConstructions(viewModel.lot_intervention_id, LotConstructionType.OE)
                : Promise.resolve([]),
            size:         4,
            observers:    [
                (newValue) => {
                    if (newValue) {
                        filterEdgeBranchPK(newValue);
                    }
                },
            ],
        };

        this.branch_id = {
            type:         'select2',
            key:          'branch_id',
            label:        branchLabel,
            remoteSource: () => Promise.resolve([]),
            size:         4,
            attributes:   {
                disabled: true,
            },
            observers:    [
                (newValue) => {
                    if (newValue)
                        updateOELotConstructionData(this.branch_id);
                },
            ],
        };

        this.edge_id = {
            type:         'select2',
            key:          'edge_id',
            label:        'form.field.edge',
            remoteSource: () => Promise.resolve([]),
            size:         4,
            attributes:   {
                disabled: true,
            },
            observers:    [
                (newValue) => {
                    if (newValue)
                        updateOELotConstructionData(this.edge_id);
                },
            ],
        };

        this.collection_pk = {
            type:     'field-group',
            label:    'form.field.collection-pk',
            key:      'collection_pk',
            required: false,
            size:     8,
            fields:   [
                {
                    type:       'divider',
                    label:      viewModel.appContainer.i18n.tr('form.field.from'),
                    size:       '30p',
                    attributes: {
                        style: 'font-size: .9rem; margin: 0 .5rem 0 .75rem; display: inline-block;',
                    },
                },
                {
                    type:            'text',
                    key:             'pk_s_from',
                    showLabel:       false,
                    required:        false,
                    size:            '130p',
                    valueConverters: [
                        { name: 'numbersOnly' },
                    ],
                    attributes:      {
                        maxlength: 3,
                    },
                },
                {
                    type:  'divider',
                    label: '+',
                    size:  '30p',
                },
                {
                    type:            'text',
                    key:             'pk_f_from',
                    showLabel:       false,
                    required:        false,
                    size:            '130p',
                    valueConverters: [
                        { name: 'autoDecimalValue' },
                    ],
                    attributes:      {
                        maxlength: 6,
                    },
                },
                {
                    type:       'divider',
                    label:      viewModel.appContainer.i18n.tr('form.field.to'),
                    size:       '30p',
                    attributes: {
                        style: 'font-size: .9rem; margin: 0 .5rem 0 1.5rem; display: inline-block;',
                    },
                },
                {
                    type:            'text',
                    key:             'pk_s_to',
                    showLabel:       false,
                    required:        false,
                    size:            '130p',
                    valueConverters: [
                        { name: 'numbersOnly' },
                    ],
                    attributes:      {
                        maxlength: 3,
                    },
                },
                {
                    type:  'divider',
                    label: '+',
                    size:  '30p',
                },
                {
                    type:            'text',
                    key:             'pk_f_to',
                    showLabel:       false,
                    required:        false,
                    size:            '130p',
                    valueConverters: [
                        { name: 'autoDecimalValue' },
                    ],
                    attributes:      {
                        maxlength: 6,
                    },
                },
            ],
        }

        this.pk_type_id = {
            type:         'select2',
            idPrefix:     this.schemaSlug,
            key:          'pk_type_id',
            label:        'form.field.pk-type',
            size:         4,
            remoteSource: () => this.pkTypesRepository.all(),
            observers:    [],
            required:     false,
        };
    }
}
