export class TestHeaderSchema {

    schemaSlug = 'test-header';

    constructor() {
        //
    }

    /**
     * Returns form schema for "Select Tests" tab
     *
     * @param viewModel
     * @param readonly
     *
     * @returns void
     */
    schema(viewModel, readonly = false) {

        const typeLabel = viewModel.type === 'lab' ? 'sample' : 'test';

        this.number = {
            type:       'text',
            key:        'number',
            label:      'form.field.' + typeLabel + '-number',
            size:       6,
            required:     false,
            attributes: {
                readonly: true,
            },
        };

        this.date = {
            type:       'material-ui-date-picker',
            key:        'date',
            label:      'form.field.' + typeLabel + '-date',
            size:       6,
            required:     false,
            attributes: {
                readonly: readonly,
            },
        };

        this.external_reference = {
            type:       'text',
            key:        'external_reference',
            label:      'form.field.external_reference',
            size:       6,
            required:     false,
            attributes: {
                readonly: readonly,
            },
        };

        this.observations = {
            type:       'textarea',
            key:        'observations',
            label:      'form.field.observations',
            size:       12,
            required:   false,
            attributes: {
                readonly: readonly,
            },
        };
    }

}
